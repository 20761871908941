import React from 'react';

const CustomSubmitButton = ({ label }) => {
    return (
        <button
            type='submit'
            className="flex flex-row items-center rounded-xl bg-gray-100 px-4 py-3 text-center font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300"
        >
            {label}
        </button>
    );
};

export default CustomSubmitButton;
