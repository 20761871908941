import axios from 'axios';

export const baseURL = 'https://server.alimlimobilya.com';
//export const baseURL = 'http://localhost:3000';

export default axios.create({ baseURL });

export const URL = {
    // Auth
    login: '/auth/login',
    tokenIsValid: '/auth/tokenisvalid',
    // Category
    getCategories: '/category/get',
    getCategoryById: '/category/getbyid/',
    addCategory: '/category/add',
    updateCategory: '/category/update/',
    deleteCategory: '/category/delete/',
    // Product
    getProduct: '/product/get/',
    getProductById: '/product/getbyid/',
    getProductByCategory: '/product/getproductbycategory/',
    addProduct: '/product/add',
    updateProduct: '/product/update/',
    deleteProduct: '/product/delete/',
    // Reference
    getReferences: '/reference/get',
    getReferenceById: '/reference/getbyid/',
    addReference: '/reference/add',
    updateReference: '/reference/update/',
    deleteReference: '/reference/delete/',
    // Photo
    addPhoto: '/photo/add',
    getPhotoById: '/photo/getbyid/',
};