import axios, { URL } from '../constants/api/axios';

export const addCategory = async (category_name, photo_id) => {
    const token = localStorage.getItem('auth_token');

    await axios.post(
        URL.addCategory,
        {
            category_name: category_name,
            photo_id: photo_id,
        },
        {
            headers: {
                'Authorization': token,
            }
        }
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Ekleme Başarısız");
        });
};

export const getCategory = async () => {
    try {
        const result = await axios.get(URL.getCategories);
        return result.data;
    } catch (error) {
        throw new Error("Kategori Getirme Başarısız");
    }
};

export const getCategoryById = async (id) => {
    try {
        const result = await axios.get(URL.getCategoryById + id);
        return result.data;
    } catch (error) {
        throw new Error("Kategori Getirme Başarısız");
    }
};

export const updateCategory = async (id, category_name, photo_id) => {
    const token = localStorage.getItem('auth_token');
    let data = { id: id, category_name: category_name };
    if (photo_id !== null && photo_id !== undefined)
        data.photo_id = photo_id;

    await axios.put(
        URL.updateCategory + id,
        data,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Güncelleme Başarısız");
        });
};

export const deleteCategory = async (id) => {
    const token = localStorage.getItem('auth_token');

    await axios.delete(
        URL.deleteCategory + id,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Silme Başarısız");
        });
};