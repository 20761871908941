import React from "react";
import image2 from '../../assets/images/home-image-2.svg';
import image4 from '../../assets/images/home-image-4.svg';
import image5 from '../../assets/images/home-image-5.svg';
import image9 from '../../assets/images/home-image-9.svg';
import image10 from '../../assets/images/home-image-10.svg';
import image11 from '../../assets/images/home-image-16.svg';
import image12 from '../../assets/images/home-image-12.svg';
import image13 from '../../assets/images/home-image-15.svg';
import image17 from '../../assets/images/home-image-17.svg';

function Home() {
    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-7 m-4">
                <div className="group">
                    <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src={image13} alt="" />
                </div>
                <div className="group">
                    <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src={image11} alt="" />
                </div>
                <div className="group">
                    <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src={image12} alt="" />
                </div>
                <div className="group">
                    <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src={image17} alt="" />
                </div>
            </div>

            <div className="p-4 md:p-6 lg:p-12 mx-4 md:mx-6 lg:mx-16 hover:bg-orange-50 m-24 font-style: italic shadow-md">
                <h5 className="font-bold mb-2 text-lg md:text-xl lg:text-2xl text-yellow-800">Alımlı Mobilya ile Ayrıcalıklı Yaşam Alanları...</h5>
                <p className="text-sm md:text-base lg:text-lg mb-4">
                    30 yılı aşkın süredir özgün tasarımlarıyla mobilya sektöründe son trendlere yön veren Alımlı Mobilya’nın her ihtiyaca, bütçeye ve iç mekân tasarımına uygun mobilya ve aksesuar modellerinin yer aldığı yepyeni koleksiyonunda, evinizi ayrıcalıklı bir yaşam alanına dönüştürmek için ihtiyacınız olan her şey mevcut!
                </p>
                <p className="text-sm md:text-base lg:text-lg">
                    Estetik ve işlevselliği bir araya getiren Alımlı Mobilya ile ayrıcalıklı yaşam ve çalışma alanlarına adım atın. Doğal malzemelerden özenle üretilen özgün tasarımlarımızla eviniz, işyeriniz ve kurumsal alanlarınız için benzersiz bir atmosfer oluşturun. Kalite, şıklık, özgünlük ve kurumsallığın buluştuğu Alımlı Mobilya ile hayalleriniz gerçeğe dönüşsün
                </p>
            </div>

            <h5 className="text-3xl font-bold text-center mb-4 m-28">İşte Bu Yüzden...</h5>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 p-7 md:p-12 m-4 md:m-4 font-style: italic">
                <div className="col-span-1 relative mt-2">
                    <img className="h-auto max-w-full rounded-lg" src={image4} alt="" />
                    <div className="absolute bottom-0 left-0 w-full text-stone-600 p-4 text-lg md:text-2xl m-4 md:m-10 lg:m-10">
                        NEDEN ALIMLI MOBİLYA ?
                    </div>
                </div>
                <div className="grid grid-cols-2 col-span-1 gap-4 text-sm md:text-base lg:text-lg">
                    <div className="relative">
                        <img className="h-auto max-w-full rounded-lg" src={image10} alt="" />
                        <div className="absolute bottom-0 left-0 w-full text-stone-800 p-2 md:p-4 lg:p-6">
                            Güvenli Üretim
                        </div>
                    </div>
                    <div className="relative">
                        <img className="h-auto max-w-full rounded-lg" src={image5} alt="" />
                        <div className="absolute bottom-0 left-0 w-full text-stone-800 p-2 md:p-4 lg:p-6">
                            <p>Ürün</p>
                            <p>Güvencesi</p>
                        </div>
                    </div>
                    <div className="relative">
                        <img className="h-auto max-w-full rounded-lg" src={image2} alt="" />
                        <div className="absolute bottom-0 left-0 w-full text-stone-800 p-2 md:p-4 lg:p-6">
                            <p>Hammadde</p>
                            <p>Kalitemiz</p>
                        </div>
                    </div>
                    <div className="relative">
                        <img className="h-auto max-w-full rounded-lg" src={image9} alt="" />
                        <div className="absolute bottom-0 left-0 w-full text-stone-800 p-2 md:p-4 lg:p-6">
                            Yerli Üretim
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;