import axios, { URL } from '../constants/api/axios';

export const login = async (username, password) => {
    await axios.post(URL.login, {
        username: username,
        password: password,
    }).then((response) => {
        localStorage.setItem('auth_token', response.data.token);
        localStorage.setItem('username', username);
        setTimeout(() => {
            window.location.href = '/';

        }, 1500);
        return response;
    })
        .catch((error) => {
            throw new Error("Giriş Başarısız");
        });
};

export const tokenIsValid = async (token) => {
    try {
        const response = await axios.post(URL.tokenIsValid, {
            token: token,
        });
        return response.data.status;
    } catch (error) {
        throw new Error("Token is invalid: " + error.message);
    }
};