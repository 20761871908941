import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginSchema } from '../../constants/schema/yup_schema';
import { login } from '../../services/auth_service';
import { SuccessToast, ErrorToast } from "../../constants/toast/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {

    const submitLogin = async (e, { setSubmitting }) => {
        try {
            await login(e.username, e.password);
            SuccessToast("Giriş Başarılı");
        } catch (error) {
            ErrorToast(error.message || "Giriş Başarısız");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section className="bg-gray-50">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                            Giriş Yap
                        </h1>
                        <Formik
                            initialValues={{ username: '', password: '' }}
                            validationSchema={LoginSchema}
                            onSubmit={submitLogin}
                        >
                            <Form className="space-y-4 md:space-y-6">
                                <div>
                                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">
                                        Kullanıcı Adı
                                    </label>
                                    <Field
                                        type="text"
                                        name="username"
                                        id="username"
                                        placeholder="Kullanıcı adı"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="username" component="div" className="text-red-500 text-sm" />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                                        Parola
                                    </label>
                                    <Field
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
                                </div>
                                <button
                                    type="submit"
                                    className="w-full text-black bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Giriş Yap
                                </button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </section>
    );
};

export default Login;