import React from "react";
import CategoryAdd from "./components/CategoryAdd";
import ProductAdd from "./components/ProductAdd";
import ReferenceAdd from "./components/ReferenceAdd";
import { useParams } from "react-router-dom";

function Add() {
    const { name } = useParams();
    return (
        <>
            {name === 'category' ? (<CategoryAdd />) :
                name === 'product' ? (<ProductAdd />) :
                    name === 'reference' ? (<ReferenceAdd />) :
                        (<></>)
            }
        </>
    );
};

export default Add;