import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ReferenceSchema } from '../../../constants/schema/yup_schema';
import { addReference, updateReference } from '../../../services/reference_service';
import { SuccessToast, ErrorToast } from "../../../constants/toast/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSubmitButton from "./CustomAddButton";
import image2 from '../../../assets/images/home-image-2.svg';
import { uploadPhoto } from '../../../helpers/Helpers';
import { useParams, useLocation } from "react-router-dom";

function ReferenceAdd() {
    const [selectedPhotoId, setSelectedPhotoId] = useState();
    const { id } = useParams();
    const location = useLocation();
    const { reference } = location.state || {};

    const referenceUpdate = async (e, { setSubmitting }) => {
        try {
            await updateReference(id, e.referencename, selectedPhotoId);
            SuccessToast("Güncelleme Başarılı");
        } catch (error) {
            ErrorToast(error.message || "Ekleme Başarısız");
        } finally {
            setSubmitting(false);
        }
    };

    const handleFileChange = async (event) => {
        const photoId = await uploadPhoto(event.target.files[0]);
        setSelectedPhotoId(photoId);
    };

    const referenceAdd = async (e, { setSubmitting }) => {
        try {
            await addReference(e.referencename, selectedPhotoId);
            SuccessToast("Ekleme Başarılı");
        } catch (error) {
            ErrorToast(error.message || "Ekleme Başarısız");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section
            className="bg-cover bg-center h-screen"
            style={{ backgroundImage: `url(${image2})` }}
        >
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        {
                            id !== undefined
                                ? (
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Referans Güncelle
                                    </h1>)
                                : (
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Referans Ekle
                                    </h1>)
                        }
                        <Formik
                            initialValues={id !== undefined
                                ? { referencename: reference.reference_name, referencephoto: '' }
                                : { referencename: '', referencephoto: '' }
                            }
                            validationSchema={ReferenceSchema}
                            onSubmit={id !== undefined ? referenceUpdate : referenceAdd}
                        >
                            <Form className="space-y-4 md:space-y-6">
                                <div>
                                    <label htmlFor="referencename" className="block mb-2 text-sm font-medium text-gray-900">
                                        Referans Adı
                                    </label>
                                    <Field
                                        type="text"
                                        name="referencename"
                                        id="referencename"
                                        placeholder="Referans adı"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="referencename" component="div" className="text-red-500 text-sm" />
                                </div>
                                <div>
                                    <label htmlFor="referencephoto" className="block mb-2 text-sm font-medium text-gray-900">
                                        Referans Fotoğrafı
                                    </label>
                                    <div className="flex row">
                                        <input type="file"
                                            name="referencephoto"
                                            id="referencephoto"
                                            placeholder="Referans Fotoğrafı"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                                {
                                    id !== undefined
                                        ? (
                                            <div className="flex justify-center">
                                                <CustomSubmitButton label={"Güncelle"} />
                                            </div>)
                                        : (
                                            <div className="flex justify-center">
                                                <CustomSubmitButton label={"Ekle"} />
                                            </div>)
                                }
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </section>
    );
};

export default ReferenceAdd;