import axios, { URL } from '../constants/api/axios';

const token = localStorage.getItem('auth_token');

export const getProducts = async () => {
    try {
        const result = await axios.get(URL.getProduct);
        return result.data;
    } catch (error) {
        throw new Error("Ürünleri getirme Başarısız");
    }
}

export const getProductByCategory = async (category_id) => {
    try {
        const result = await axios.get(URL.getProductByCategory + category_id);
        return result.data;
    } catch (error) {
        throw new Error("Ürün getirme Başarısız");
    }
}

export const addProduct = async (product_name, product_price, product_contents, category_id, product_description, photo_id) => {
    await axios.post(
        URL.addProduct,
        {
            product_name: product_name,
            product_price: product_price,
            photo_id: photo_id,
            product_description: product_description,
            category_id: category_id,
            product_contents: product_contents
        },
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Ekleme Başarısız");
        });
};

export const getProductById = async (id) => {
    try {
        const result = await axios.get(URL.getProductById + id);
        return result.data;
    } catch (error) {
        throw new Error("Ürün getirme Başarısız");
    }
};

export const updateProduct = async (id, product_name, product_price, product_description, product_contents, photo_id) => {
    let data = { id: id, product_name: product_name, product_price: product_price, product_description: product_description, product_contents: product_contents };
    if (photo_id !== null && photo_id !== undefined)
        data.photo_id = photo_id;

    await axios.put(
        URL.updateProduct + id,
        data,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Güncelleme Başarısız");
        });
};

export const deleteProduct = async (id) => {
    await axios.delete(
        URL.deleteProduct + id,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Silme Başarısız");
        });
};