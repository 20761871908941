import React from 'react';

const CustomDetailButton = ({ onClick, label }) => {
    return (
        <button
            className="flex flex-row items-center rounded-xl bg-gray-100 px-4 py-3 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300"
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default CustomDetailButton;
