import axios, { URL } from '../constants/api/axios';

export const addReference = async (reference_name, photo_id) => {
    const token = localStorage.getItem('auth_token');

    await axios.post(
        URL.addReference,
        {
            reference_name: reference_name,
            photo_id: photo_id,
        },
        {
            headers: {
                'Authorization': token,
            }
        }
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Ekleme Başarısız");
        });
};

export const getReference = async () => {
    const result = await axios.get(URL.getReferences);
    return result.data;
};

export const getReferenceById = async (id) => {
    const result = await axios.get(URL.getReferenceById + id);
    return result.data;
};

export const updateReference = async (id, reference_name, photo_id) => {
    const token = localStorage.getItem('auth_token');
    let data = { id: id, reference_name: reference_name };
    if (photo_id !== null && photo_id !== undefined)
        data.photo_id = photo_id;
    await axios.put(
        URL.updateReference + id,
        data,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Ekleme Başarısız");
        });
};


export const deleteReference = async (id) => {
    const token = localStorage.getItem('auth_token');

    await axios.delete(
        URL.deleteReference + id,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Ekleme Başarısız");
        });
};