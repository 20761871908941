import React from 'react';

const CustomUpdateButton = ({ onClick, label }) => {
    return (
        <button
            className="rounded-xl border-2 border-yellow-500 px-5 py-3 text-base font-medium text-yellow-500 transition duration-200 hover:bg-yellow-600/5 active:bg-yellow-700/5"
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default CustomUpdateButton;
