import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import About from '../pages/about/About';
import Contact from '../pages/contact/Contact';
import Category from '../pages/products/Category';
import Product from '../pages/products/Product';
import Project from '../pages/project/Project';
import ColorCatalog from '../pages/colorcatalog/ColorCatalog';
import Login from '../pages/login/Login';
import Detail from '../pages/products/Detail';
import Add from '../pages/add/Add';

function Router() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/category' element={<Category />} />
            <Route path='/project' element={<Project />} />
            <Route path='/colorcatalog' element={<ColorCatalog />} />
            <Route path='/colorcatalog:id' element={<ColorCatalog />} />
            <Route path='/category:id' element={<Category />} />
            <Route path='/category/:category_name' element={<Product />} />
            <Route path='/:name/:category_name/update/:id' element={<Add />} />
            <Route path='/:name/update/:id' element={<Add />} />
            <Route path='/:name/update/:id' element={<Add />} />
            <Route path='/product/detail/:id' element={<Detail />} />
            <Route path='/login' element={<Login />} />
            <Route path='/add/:name' element={<Add />} />
        </Routes>
    );
};

export default Router;