import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteReference } from "../../../services/reference_service";
import CustomDeleteButton from "../../../components/button/CustomDeleteButton";
import CustomUpdateButton from "../../../components/button/CustomUpdateButton";
import { arrayBufferToBase64 } from "../../../helpers/Helpers";
import NoPhoto from "../../../assets/images/no-photo.png";
import { tokenIsValid } from "../../../services/auth_service";

function Card({ reference, onDelete }) {
    const navigate = useNavigate();
    const [token, setToken] = useState(null);

    useEffect(() => {
        tokenControl();
    }, [])

    const tokenControl = async () => {
        const isValid = await tokenIsValid(localStorage.getItem('auth_token'));
        setToken(isValid);
    }

    const updateCard = (id) => {
        navigate('/reference/update/' + id, { state: { reference } });
    }

    const deleteCard = (id) => {
        deleteReference(id);
        onDelete();
    }

    return (
        <div className="border justify-center align-center rounded-lg p-4 w-full max-w-md mx-auto bg-white border border-gray-200 rounded-lg shadow-lg">
            {token && (
                <div className="flex justify-between mb-4">
                    <CustomDeleteButton onClick={() => deleteCard(reference.id)} label={'Sil'} />
                    <CustomUpdateButton onClick={() => updateCard(reference.id)} label={'Güncelle'} />
                </div>
            )}
            {
                reference.photo_data != null ? (
                    <img className="p-8 rounded-t-lg" src={arrayBufferToBase64(reference.photo_data.data)} alt={reference.reference_name} />
                ) : (
                    <img className="p-8 rounded-t-lg" src={NoPhoto} alt={reference.reference_name} />
                )
            }
            <div className="px-5 pb-5">
                <h5 className="flex items-center justify-center text-xl font-semibold tracking-tight text-gray-900">{reference.reference_name}</h5>
            </div>
        </div>
    );
};

export default Card;
