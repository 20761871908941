import React from "react";

function ColorCatalog() {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-6 gap-4 p-7 m-4">
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Deep-Blue-1.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Deep Blue</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Chrome-Yellow-1.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Chrome Yellow</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Terquoise-1.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Turquoise</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Signal-Red.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Signal Red</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Charcoal.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Charcoal</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Plum.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Plum</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Grey.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Grey</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Lemon-Yellow-1.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Lemon Yellow</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Terracotta-Dark.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Terra Cotta Dark</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Marine-Green-1.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Marine Green</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/Orange-1.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Orange</p>
            </div>
            <div className="group">
                <img className="h-auto max-w-full rounded-lg transform transition duration-300 ease-in-out group-hover:scale-110" src="https://heritagepaints.co.za/wp-content/uploads/2022/06/PPC-Red.jpg" alt="" />
                <p className="flex flex-row justify-center text-base font-medium text-gray-900 hover:text-gray-700">Ppc Red</p>
            </div>
        </div>
    );
};

export default ColorCatalog;