import React from "react";

export default function Dropdown({ values, onCategoryChange }) {

    const handleChange = (e) => {
        const selectedCategory = e.target.value;
        onCategoryChange(selectedCategory);
    }

    return (
        <div className="relative w-full lg:max-w-sm">
            <select
                className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                onChange={handleChange}
            >
                {values.map((value) => (
                    <option key={value.id} value={value.id}>{value.category_name}</option>
                ))}
            </select>
        </div>
    );
}