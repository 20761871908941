import React from 'react';

const CustomDeleteButton = ({ onClick, label }) => {
    return (
        <button
            className="rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5"
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default CustomDeleteButton;
